import { FC } from 'react'
import classes from './secondary-layout.module.scss'
import { IntercomChat } from 'src/modules/intercom'

export const SecondaryLayout: FC = ({ children }) => {
  return (
    <>
      <IntercomChat />
      <div className={classes.root}>{children}</div>
    </>
  )
}
