import { FC, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTggl } from 'react-tggl-client'
import { useObservable } from 'src/modules/core/hooks/use-observable'
import { AuthContext } from 'src/modules/user/context'
import { TokenService } from 'src/services/token.service'
import { shutdown } from '@intercom/messenger-js-sdk'

export const AuthProvider: FC = ({ children }) => {
  const token = useObservable(TokenService.getInstance().getToken())
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(token !== null)

  const { updateContext } = useTggl()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!isAuthenticated) {
      // Update Tggl context when user logs out
      updateContext({ userId: null, email: null })
    }
  }, [isAuthenticated, updateContext, queryClient])

  const [isReauthorizingForOfflineSync, setIsReauthorizingForOfflineSync] = useState<boolean>(false)

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticateUser: () => setIsAuthenticated(true),
        logoutUser: () => {
          setIsAuthenticated(false)
          TokenService.getInstance().removeToken()
          queryClient.removeQueries()
          document.querySelector('.paddle-frame')?.remove()
          shutdown()
        },
        isReauthorizingForOfflineSync,
        setIsReauthorizingForOfflineSync,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
