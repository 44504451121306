import { RouteNamesType } from 'src/modules/core/types/routes.types'

export const reminderEditRoute: string = '/reminder/edit'

export const routeNames: RouteNamesType = {
  dashboardPrefix: {
    path: '/dashboard',
    relative: 'dashboard/*',
  },
  dashboard: {
    path: '/dashboard/home',
    relative: 'home',
  },
  login: {
    path: '/onboarding/login',
    relative: 'login',
  },
  loginWithToken: {
    path: '/onboarding/login-with-token',
    relative: 'login-with-token',
  },
  settings: {
    path: '/dashboard/settings',
    relative: 'settings',
  },
  onboarding: {
    path: '/onboarding',
    relative: 'onboarding/*',
  },
  home: {
    path: '/',
    relative: '*',
  },
  configuration: {
    path: '/onboarding/configuration',
    relative: 'configuration',
  },
  terms: {
    path: '/terms',
    relative: 'terms',
  },
  privacy: {
    path: '/privacy',
    relative: 'privacy',
  },
  confirmation: {
    path: '/confirmation',
    relative: 'confirmation/:urlCode',
  },
  cancelPlan: {
    path: '/subscription/cancel',
    relative: 'subscription/cancel',
  },
  canceledSubscription: {
    path: '/subscription/canceled',
    relative: 'subscription/canceled',
  },
  pausedSubscription: {
    path: '/subscription/paused',
    relative: 'subscription/paused',
  },
  betaUsersSubscription: {
    path: '/subscription/beta',
    relative: 'subscription/beta',
  },
  subscriptionPlans: {
    path: '/subscription/plans',
    relative: 'subscription/plans',
  },
  support: {
    path: '/support',
    relative: 'support',
  },
}
