import { useSnackbar } from 'notistack'
import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTggl } from 'react-tggl-client'
import { routeNames } from 'src/modules/core/constants/routeNames'
import { LoadingWrapperContext } from 'src/modules/core/context'
import { evaluateRouteAccess } from 'src/modules/core/utils/router.utils'
import { GET_SUBSCRIPTION_CACHE_KEY } from 'src/modules/payment/hooks/use-subscription'
import { AuthContext } from 'src/modules/user/context'
import { GET_PROFILE_CACHE_KEY } from 'src/modules/user/hooks/use-profile'
import { ApiService } from 'src/services/api.service'
import { TokenService } from 'src/services/token.service'

export const LoginWithToken: FC = () => {
  const [hasStarted, setHasStarted] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { updateContext: updateTgglContext } = useTggl()
  const { authenticateUser, logoutUser } = useContext(AuthContext)
  const { enableLoading, disableLoading, setLoadingMessage } = useContext(LoadingWrapperContext)
  const location = useLocation()

  const handleLoginFailure = useCallback(() => {
    disableLoading()
    logoutUser()
    enqueueSnackbar(formatMessage({ id: 'login.error.unknown' }), { variant: 'error' })
    navigate(routeNames.login.path)
  }, [logoutUser, disableLoading, enqueueSnackbar, formatMessage, navigate])

  const handleTokenSuccess = useCallback(
    async (accessToken: string) => {
      TokenService.getInstance().setToken(accessToken)
      authenticateUser()

      const params = {}
      const profile = await queryClient.fetchQuery(
        [GET_PROFILE_CACHE_KEY, params],
        () => ApiService.Profile.getProfile(params),
        { staleTime: 300000 },
      )

      if (!profile) {
        handleLoginFailure()
        return
      }

      updateTgglContext({ userId: profile.id, email: profile.email })

      const subscription = profile.billing_subscription_id
        ? await queryClient.fetchQuery(GET_SUBSCRIPTION_CACHE_KEY, ApiService.Billing.getSubscription, {
            staleTime: 300_000,
          })
        : null

      const { redirect, redirectPath } = evaluateRouteAccess({
        path: routeNames.dashboard.path,
        isAuthenticated: true,
        restricted: true,
        subscription,
        profile,
        isLoading: false,
      })

      setHasStarted(false)

      // Get redirect URL from query params, fallback to dashboard
      const searchParams = new URLSearchParams(location.search)
      const redirectUrl = searchParams.get('redirect') || (redirect ? redirectPath : routeNames.dashboard.path)
      navigate(redirectUrl)
    },
    [authenticateUser, queryClient, updateTgglContext, handleLoginFailure, navigate, location.search],
  )

  const checkTokenAndLogin = useCallback(
    async (encodedToken: string | null) => {
      enableLoading()
      setLoadingMessage('Authenticating...')
      if (!encodedToken) {
        handleLoginFailure()
        return
      }

      try {
        const token = atob(encodedToken)
        await handleTokenSuccess(token)
      } catch (error) {
        handleLoginFailure()
      }
    },
    [enableLoading, setLoadingMessage, handleTokenSuccess, handleLoginFailure],
  )

  useEffect(() => {
    if (hasStarted) return

    setHasStarted(true) // Set guard to true to prevent re-execution
    const params = new URLSearchParams(location.search)
    const encodedToken = params.get('token')
    if (encodedToken) checkTokenAndLogin(encodedToken)
    else handleLoginFailure()
  }, [hasStarted, checkTokenAndLogin, handleLoginFailure, location.search])

  return null
}
