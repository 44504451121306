import { useContext, useEffect } from 'react'
import { LoadingWrapperContext } from 'src/modules/core/context'
import { IntercomChat } from 'src/modules/intercom'
import classes from './support.module.scss'
import { Icon } from 'src/modules/core/components'
import { Typography } from 'src/modules/core/components'
import { FormattedText } from 'src/modules/core/components'

export function Support() {
  const { disableLoading } = useContext(LoadingWrapperContext)

  useEffect(() => {
    disableLoading()
  }, [disableLoading])

  return (
    <div className={classes.root}>
      <IntercomChat startChat />

      <div className={classes.container}>
        <div className={classes.banner}>
          <Icon name="googleCalendar" className={classes.calendarIcon} />
          <Icon name="imessage" className={classes.messageIcon} />
          <Typography variant="span" className={classes.message}>
            <FormattedText id="login.exampleMessage" />
          </Typography>
        </div>
        <Typography variant="body" className={classes.title}>
          Let us know how we can help
        </Typography>
      </div>
    </div>
  )
}
