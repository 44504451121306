import classNames from 'classnames'
import { Sidebar } from '../../components/sidebar/sidebar'
import { SidebarProvider, useSidebar } from '../../context/sidebar.context'
import classes from './main-layout.module.scss'
import { IntercomChat } from 'src/modules/intercom'

export function MainLayout({ children }) {
  return (
    <SidebarProvider>
      <IntercomChat />
      <div className={classes.root}>
        <Sidebar />
        <MainLayoutContent>{children}</MainLayoutContent>
      </div>
    </SidebarProvider>
  )
}

function MainLayoutContent({ children }) {
  const { isCollapsed } = useSidebar()
  return <div className={classNames(classes.content, { [classes.contentExpanded]: isCollapsed })}>{children}</div>
}
