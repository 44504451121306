import type { BillingPlanDto, BillingSubscriptionDto } from '@grouse-software/types/billing/billing.dto'
import { UserDto } from 'src/helpers/user.dto'
import { routeNames } from 'src/modules/core/constants/routeNames'
import { SubscriptionStatus } from 'src/modules/payment/types/payment.types'

/**
 * Determines whether a user should be redirected based on their authentication status,
 * subscription details, profile information, and the current route.
 */
export const evaluateRouteAccess = (props: {
  path: string
  isAuthenticated: boolean
  restricted: boolean
  subscription: BillingSubscriptionDto
  profile: UserDto
  isLoading: boolean
  currentPlan?: BillingPlanDto
}) => {
  const { path, isAuthenticated, restricted, subscription, profile, isLoading, currentPlan } = props

  let redirectPath: string

  // Only check authentication for restricted routes
  if (!isAuthenticated && restricted) {
    redirectPath = routeNames.login.path
  }

  if (isAuthenticated && path === routeNames.login.path) {
    redirectPath = routeNames.dashboard.path
  }

  // Only check subscription and profile for authenticated users
  if (isAuthenticated && !isLoading && !isRouteWithoutActivePlan(path)) {
    if (!profile || !currentPlan) {
      redirectPath = null
    } else {
      // for users who did not complete onboarding
      if (!profile?.completed_onboarding && isRouteWithCompletedOnboarding(path)) {
        redirectPath = routeNames.configuration.path
      }

      // for users who completed onboarding
      if (profile?.completed_onboarding && isOnboardingRoute(path)) {
        redirectPath = routeNames.dashboard.path
      }

      // For users with subscriptions, otherwise continue with free plan flow
      if (subscription) {
        // if subscription is canceled
        if (subscription?.state === SubscriptionStatus.deleted && path !== routeNames.canceledSubscription.path) {
          if (Date.now() >= parseInt(profile?.billing_cancellation_effective_date, 10)) {
            redirectPath = routeNames.canceledSubscription.path
          }
        }

        // if subscription is paused
        if (
          (subscription?.state === SubscriptionStatus.paused || subscription?.paused_at) &&
          path !== routeNames.pausedSubscription.path
        ) {
          redirectPath = routeNames.pausedSubscription.path

          return { redirectPath, redirect: !!redirectPath }
        }
      }

      // For free plan users who have reached their limit
      if (
        !profile.billing_subscription_id &&
        profile.reminders_sent_during_billing_cycle >= currentPlan.reminders_limit
      ) {
        redirectPath = `${routeNames.subscriptionPlans.path}?type=limit`
      }

      // for existing beta users
      if (
        !profile?.billing_subscription_id &&
        profile?.has_benefits &&
        profile?.completed_onboarding &&
        path !== routeNames.betaUsersSubscription.path
      ) {
        redirectPath = routeNames.betaUsersSubscription.path
      }
    }
  }

  return { redirectPath, redirect: !!redirectPath }
}

export function isRouteWithoutActivePlan(path: string): boolean {
  return path.includes('confirmation') || path === routeNames.terms.path || path === routeNames.privacy.path
}

function isRouteWithCompletedOnboarding(path: string): boolean {
  return path.includes('dashboard') || path.includes('subscription')
}

function isOnboardingRoute(path: string): boolean {
  return path.includes('onboarding') && path !== routeNames.login.path
}
