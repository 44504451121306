import { useEffect } from 'react'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import Intercom, { update, showNewMessage } from '@intercom/messenger-js-sdk'

export interface IntercomChatProps {
  show?: boolean
  startChat?: boolean
}

const INTERCOM_APP_ID = 'mmo1yzwl'

export function IntercomChat({ show = true, ...rest }: IntercomChatProps) {
  if (!show) {
    return null
  }

  if (location.pathname.includes('onboarding/login')) {
    return <IntercomChatAnonymous {...rest} />
  }
  return <IntercomChatAuthenticated {...rest} />
}

function IntercomChatAnonymous({ startChat }: IntercomChatProps) {
  Intercom({
    app_id: INTERCOM_APP_ID,
  })
  update({
    user_id: null,
    name: null,
    email: null,
    created_at: null,
  })
  if (startChat) {
    showNewMessage('')
  }
  return null
}

function IntercomChatAuthenticated({ startChat }: IntercomChatProps) {
  const profile = useProfile()

  Intercom({
    app_id: INTERCOM_APP_ID,
  })

  useEffect(() => {
    if (profile.data) {
      update({
        user_id: profile.data.id,
        name: profile.data.full_name,
        email: profile.data.email,
        created_at: Math.floor(new Date(profile.data.created_at).getTime() / 1000),
      })
    }
    if (startChat) {
      showNewMessage('')
    }
  }, [profile.data, startChat])

  return null
}
