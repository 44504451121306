import { routeNames } from 'src/modules/core/constants/routeNames'
import { GeneralLayout, MainLayout, SecondaryLayout } from 'src/modules/core/layout'
import {
  BetaUsersSubscription,
  CanceledSubscription,
  CancelPlan,
  Configuration,
  Confirmation,
  Dashboard,
  Login,
  PausedSubscription,
  Privacy,
  Settings,
  SubscriptionPlans,
  Terms,
} from 'src/pages'
import { LoginWithToken } from 'src/pages/login-with-token'
import { Support } from 'src/pages/support'
import type { RouteType } from 'src/modules/core/types/routes.types'

export const routes: RouteType[] = [
  {
    layout: SecondaryLayout,
    pathLayout: routeNames.onboarding.relative,
    components: [
      {
        relativePath: routeNames.configuration.relative,
        path: routeNames.configuration.path,
        component: Configuration,
        restricted: true,
      },
      {
        relativePath: routeNames.login.relative,
        path: routeNames.login.path,
        component: Login,
        restricted: false,
      },
      {
        relativePath: routeNames.loginWithToken.relative,
        path: routeNames.loginWithToken.path,
        component: LoginWithToken,
        restricted: false,
      },
    ],
  },
  {
    layout: MainLayout,
    pathLayout: routeNames.dashboardPrefix.relative,
    components: [
      {
        relativePath: routeNames.dashboard.relative,
        path: routeNames.dashboard.path,
        component: Dashboard,
        restricted: true,
      },
      {
        relativePath: routeNames.settings.relative,
        path: routeNames.settings.path,
        component: Settings,
        restricted: true,
      },
    ],
  },
  {
    layout: GeneralLayout,
    pathLayout: routeNames.home.relative,
    components: [
      {
        relativePath: routeNames.terms.relative,
        path: routeNames.terms.path,
        component: Terms,
        restricted: false,
      },
      {
        relativePath: routeNames.privacy.relative,
        path: routeNames.privacy.path,
        component: Privacy,
        restricted: false,
      },
      {
        relativePath: routeNames.confirmation.relative,
        path: routeNames.confirmation.path,
        component: Confirmation,
        restricted: false,
      },
      {
        relativePath: routeNames.cancelPlan.relative,
        path: routeNames.cancelPlan.path,
        component: CancelPlan,
        restricted: true,
      },
      {
        relativePath: routeNames.canceledSubscription.relative,
        path: routeNames.canceledSubscription.path,
        component: CanceledSubscription,
        restricted: true,
      },
      {
        relativePath: routeNames.pausedSubscription.relative,
        path: routeNames.pausedSubscription.path,
        component: PausedSubscription,
        restricted: true,
      },
      {
        relativePath: routeNames.betaUsersSubscription.relative,
        path: routeNames.betaUsersSubscription.path,
        component: BetaUsersSubscription,
        restricted: true,
      },
      {
        relativePath: routeNames.subscriptionPlans.relative,
        path: routeNames.subscriptionPlans.path,
        component: SubscriptionPlans,
        restricted: true,
      },
      {
        relativePath: routeNames.support.relative,
        path: routeNames.support.path,
        component: Support,
        restricted: false,
      },
    ],
  },
]
