import { FC } from 'react'
import { routeNames } from 'src/modules/core/constants/routeNames'
import { IntercomChat } from 'src/modules/intercom/intercom-chat'
import classes from './general-layout.module.scss'

const showIntercomChat =
  location.pathname !== routeNames.terms.path &&
  location.pathname !== routeNames.privacy.path &&
  !location.pathname.includes(routeNames.confirmation.path)

export const GeneralLayout: FC = ({ children }) => {
  return (
    <>
      <IntercomChat show={showIntercomChat} />
      <div className={classes.root}>
        <div className={classes.content}>{children}</div>
      </div>
    </>
  )
}
