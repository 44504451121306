import { FC, useContext, useEffect, useMemo } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
// components
import { Loader } from 'src/modules/core/components'
import { routeNames } from 'src/modules/core/constants/routeNames'
// constants
import { routes } from 'src/modules/core/constants/routes'
// context
import { LoadingWrapperContext } from 'src/modules/core/context'
// hooks
import { useObservable } from 'src/modules/core/hooks/use-observable'
// utils
import { evaluateRouteAccess, isRouteWithoutActivePlan } from 'src/modules/core/utils/router.utils'
import { usePlanData } from 'src/modules/payment/hooks/use-plan-data'
import { useSubscription } from 'src/modules/payment/hooks/use-subscription'
import { isSubscriptionActive } from 'src/modules/payment/utils/helpers'
import { AuthContext } from 'src/modules/user/context'
import { useProfile } from 'src/modules/user/hooks/use-profile'
import { useSyncProfile } from 'src/modules/user/hooks/use-sync-profile'
// services
import { TokenService } from 'src/services/token.service'

// types
import type { ProtectedRouteProps } from './router.interface'

const ProtectedRoute: FC<ProtectedRouteProps> = ({ restricted, path, children }) => {
  // hooks
  const token = useObservable(TokenService.getInstance().getToken())

  const isUserResourceEnabled = Boolean(token !== null && !isRouteWithoutActivePlan(path) && restricted)

  const profile = useProfile({}, { enabled: isUserResourceEnabled })

  const subscriptionOptions = {
    enabled: Boolean(profile.data?.billing_subscription_id),
  }

  const subscriptionPlansOptions = {
    enabled: isUserResourceEnabled,
  }

  const subscription = useSubscription({}, subscriptionOptions)
  const { currentPlan, loading } = usePlanData({
    subscriptionOptions,
    subscriptionPlansOptions,
  })
  // contexts
  const { isAuthenticated } = useContext(AuthContext)
  // memo
  // TODO: adding subscription.isLoading and usePlanData.loading to the memo will cause the page to load indefinitely
  const isLoading = useMemo(
    () => restricted && (loading || subscription.isLoading || profile.isLoading),
    [loading, profile.isLoading, subscription.isLoading, restricted],
  )

  const { redirectPath, redirect } = evaluateRouteAccess({
    path,
    isAuthenticated,
    restricted,
    subscription: subscription.data,
    profile: profile.data,
    isLoading,
    currentPlan,
  })

  if (redirect && !redirectPath.includes(path)) {
    return <Navigate to={redirectPath} replace />
  }

  if (isLoading && path !== routeNames.login.path && path !== routeNames.loginWithToken.path) {
    return <Loader isLoading={isLoading} full />
  }

  return <>{children}</>
}

export const Router: FC = () => {
  // hooks
  const location = useLocation()
  const token = useObservable(TokenService.getInstance().getToken())
  const syncProfile = useSyncProfile()

  const profile = useProfile(
    {},
    {
      enabled: Boolean(token !== null && !isRouteWithoutActivePlan(location.pathname)),
    },
  )

  const subscription = useSubscription(
    {},
    {
      enabled: Boolean(token !== null && Boolean(profile.data?.billing_subscription_id)),
    },
  )

  // contexts
  const { isLoading, loadingMessage } = useContext(LoadingWrapperContext)
  const { isAuthenticated } = useContext(AuthContext)

  // memo
  const redirectRouteName = useMemo(
    () => (isAuthenticated ? routeNames.dashboard.path : routeNames.login.path) + location.search,
    [isAuthenticated, location.search],
  )

  const isDashboard = useMemo(() => location.pathname.includes('dashboard'), [location.pathname])

  // if (googleProfile.data?.email) {
  //   Sentry.setUser({ email: googleProfile.data?.email })
  // }

  useEffect(() => {
    if (
      isDashboard &&
      isSubscriptionActive(subscription.data?.state, profile.data?.billing_cancellation_effective_date)
    ) {
      syncProfile.mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, subscription.data?.state, isDashboard])

  return (
    <>
      <Loader isLoading={isLoading} text={loadingMessage} full />
      <Routes>
        {routes.map(({ pathLayout, components, layout: Layout }) => (
          <Route
            key={pathLayout}
            path={pathLayout}
            element={
              <Layout>
                <Routes>
                  {components.map(({ relativePath, path, restricted, component: Component }) => (
                    <Route
                      key={relativePath}
                      path={relativePath}
                      element={
                        <ProtectedRoute restricted={restricted} path={path}>
                          <Component />
                        </ProtectedRoute>
                      }
                    />
                  ))}
                  <Route
                    path="*"
                    element={
                      <ProtectedRoute restricted={isAuthenticated} path={redirectRouteName}>
                        <Navigate to={redirectRouteName} replace />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </Layout>
            }
          />
        ))}
      </Routes>
    </>
  )
}
